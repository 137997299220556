import { h } from 'preact'; /** @jsx h */
import { useState } from 'preact/hooks';

import { addLocale, locale } from 'primereact/api';

import { Calendar } from 'primereact/calendar';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';

import { Select, SelectItem } from '@tremor/react';
import { FORMATS } from '../../utils/formatDates';

import './DateRangePicker.css';

locale('no');
addLocale('no', {
  firstDayOfWeek: FORMATS.FIRSTDAYOFWEEK,
  dayNames: FORMATS.DAYNAMES,
  dayNamesShort: FORMATS.DAYNAMESSHORT,
  dayNamesMin: FORMATS.DAYNAMESMIN,
  monthNames: FORMATS.MONTHNAMES,
  monthNamesShort: FORMATS.MONTHNAMESSHORT,
  today: FORMATS.TODAY,
  clear: FORMATS.CLEAR
});

const DateRangePicker = ({
  dateRange,
  setDateRange,
  setOpenRow = false,
  presets = true,
  presetDirection,
  minDate = new Date('2020', '0', '1'),
  maxDate = new Date(),
  ...props
}) => {
  const [selectedOption, setSelectedOption] = useState(
    presetDirection === 'backward' ? 'lastTwelveMonths' : 'lastThisNextMonth'
  );

  const handleDateChange = (e) => {
    setDateRange(e.value);
    setSelectedOption(''); // Reset the selected option when date range changes
    if (setOpenRow) setOpenRow(null); // Reset the view when date range changes
  };

  const handleSelectChange = (newValue) => {
    setSelectedOption(newValue);
    switch (newValue) {
      case 'lastThisNextMonth':
        setDateRange(lastThisAndNextMonth);
        break;
      case 'nextThreeMonth':
        setDateRange(nextThreeMonth);
        break;
      case 'nextMonth':
        setDateRange(nextMonth);
        break;
      case 'thisMonth':
        setDateRange(thisMonth);
        break;
      case 'lastMonth':
        setDateRange(lastMonth);
        break;
      case 'lastOneMonth':
        setDateRange(lastOneMonth);
        break;
      case 'lastThreeMonths':
        setDateRange(lastThreeMonths);
        break;
      case 'lastSixMonths':
        setDateRange(lastSixMonths);
        break;
      case 'lastTwelveMonths':
        setDateRange(lastTwelveMonths);
        break;
      case 'thisYear':
        setDateRange(thisYear);
        break;
      case 'thisWholeYear':
        setDateRange(thisWholeYear);
        break;
      case 'lastYear':
        setDateRange(lastYear);
        break;
      default:
        break;
    }
    if (setOpenRow) setOpenRow(null); // Reset the view when date range changes
  };

  const lastThisAndNextMonth = [
    new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
    new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0)
  ];

  const nextThreeMonth = [
    new Date(),
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + 90
    )
  ];

  const nextMonth = [
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1),
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
  ];

  const thisMonth = [
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
  ];

  const lastMonth = [
    new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
    new Date(new Date().getFullYear(), new Date().getMonth(), 0)
  ];

  const lastOneMonth = [
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 30
    ),
    new Date()
  ];

  const lastThreeMonths = [
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 90
    ),
    new Date()
  ];

  const lastSixMonths = [
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 180
    ),
    new Date()
  ];

  const lastTwelveMonths = [
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 365
    ),
    new Date()
  ];

  const thisYear = [new Date(new Date().getFullYear(), 0, 1), new Date()];

  const thisWholeYear = [
    new Date(new Date().getFullYear(), 0, 1),
    new Date(new Date().getFullYear(), 11, 31)
  ];

  const lastYear = [
    new Date(new Date().getFullYear() - 1, 0, 1),
    new Date(new Date().getFullYear() - 1, 11, 31)
  ];

  return (
    <div className="w-full flex flex-col md:flex-row space-y-2 md:space-y-0">
      <Calendar
        className={`${presets ? 'hasPresets' : ''}`}
        value={dateRange}
        onChange={handleDateChange}
        selectionMode="range"
        readOnlyInput
        dateFormat="d. M yy"
        locale="no"
        showIcon
        minDate={minDate}
        maxDate={maxDate}
        {...props}
      />
      {presets && presetDirection === 'backward' && (
        <Select
          value={selectedOption}
          onValueChange={handleSelectChange}
          enableClear={false}
          placeholder="Velg periode"
          color="blue"
          className="datepicker min-w-[11rem] z-20"
        >
          <SelectItem value="lastOneMonth"> Siste 30 dager</SelectItem>
          <SelectItem value="lastThreeMonths">Siste 90 dager</SelectItem>
          <SelectItem value="lastSixMonths"> Siste 6 måneder</SelectItem>
          <SelectItem value="lastTwelveMonths">Siste 12 måneder</SelectItem>
          <SelectItem value="thisYear">Hittil i år</SelectItem>
          <SelectItem value="lastYear">Hele forrige år</SelectItem>
        </Select>
      )}
      {presets && presetDirection === 'foreward' && (
        <Select
          value={selectedOption}
          onValueChange={handleSelectChange}
          enableClear={false}
          placeholder="Velg periode"
          color="blue"
          className="datepicker min-w-[11rem]  z-20"
        >
          <SelectItem value="lastThisNextMonth">Nå +/- 1 måned</SelectItem>
          <SelectItem value="nextThreeMonth">Neste 3 måneder</SelectItem>
          <SelectItem value="nextMonth">Neste måned</SelectItem>
          <SelectItem value="thisMonth">Denne måneden</SelectItem>
          <SelectItem value="lastMonth"> Forrige måned</SelectItem>
          <SelectItem value="lastThreeMonths">Forrige 3 måneder</SelectItem>
          <SelectItem value="thisWholeYear">Hele dette året</SelectItem>
          <SelectItem value="lastYear">Hele forrige år</SelectItem>
        </Select>
      )}
    </div>
  );
};

export default DateRangePicker;
